//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.rowMenu
{
    position:    relative;
    display:     flex;
    align-items: center;
    width:       fit-content;
    margin-left: auto;
    cursor:      pointer;

    > span
    {
        width: fit-content;

        @include svgColor($mediumGray);

        svg
        {
            $size:  26px;

            width:  $size;
            height: $size;
        }
    }

    .menu
    {
        z-index:        10;
        position:       absolute;
        opacity:        0;
        pointer-events: none;
        min-width:      180px;
        width:          fit-content;
        right:          0;
        top:            0;
        transition:     opacity 0.2s ease-in-out;
        border:         1px solid;

        &.open
        {
            opacity:        1;
            pointer-events: all;
        }

        .menuItem
        {
            cursor:     pointer;
            display:    flex;
            padding:    12px 12px;
            color:      $white;
            font-size:  14px;
            transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

            @include svgColor($white);

            > span:first-child
            {
                width: 30px;

                svg
                {
                    $size:  16px;

                    width:  $size;
                    height: $size;
                }
            }

            &:hover
            {

                @include svgColor($lightGreen);
            }
        }
    }
}

tr
{
    &:is(:last-child)
    {
        .rowMenu
        {
            .menu
            {
                bottom: 0;
                top:    auto;
            }
        }
    }
}
