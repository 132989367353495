//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.editObjectScreen
{
    display:        flex;
    flex-direction: column;
    gap:            30px;

    .contentColumns
    {
        display:               grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:              20px;

        > div
        {
            display:        flex;
            flex-direction: column;
            gap:            20px;
        }

        .zipAndCity
        {
            display: flex;

            > div:first-child
            {
                width:        300px;
                margin-right: 20px;
            }
        }
    }

    button
    {
        width: fit-content;
    }
}

@media (max-width: $screen-sm)
{
    .editObjectScreen
    {
        gap: 20px;

        .contentColumns
        {
            grid-template-columns: 1fr;

            > div
            {
                gap: 15px;
            }

            .zipAndCity
            {
                flex-direction: column;

                > div:first-child
                {
                    width:         100%;
                    margin-right:  0;
                    margin-bottom: 15px;
                }
            }
        }

        button
        {
            width: 100%;
        }
    }
}

@media (max-width: $screen-xs)
{
    .editObjectScreen
    {
        gap: 10px;

        .contentColumns
        {
            grid-gap: 10px;

            > div
            {
                gap: 10px;
            }
        }

        button
        {
            width:   100%;
            padding: 10px;
        }
    }
}
