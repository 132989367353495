//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.listWidget
{
    width:          100%;
    height:         100%;
    border:         1px solid $lightGray;
    display:        flex;
    flex-direction: column;
    padding:        10px;
    gap:            10px;

    > h4
    {
        text-transform: uppercase;
    }

    .listContainer
    {
        display:    grid;
        overflow:   auto;
        max-height: 300px;

        .listItem
        {
            padding:    10px 6px;
            display:    flex;
            gap:        20px;
            cursor:     pointer;
            transition: background 0.1s ease-in-out;

            span
            {
                text-align:  left;
                width:       fit-content;
                display:     inline-flex;
                align-items: center;

                @include svgColor($black);

                svg
                {
                    $size:  18px;

                    width:  $size;
                    height: $size;
                }
            }

            &:nth-child(odd)
            {
                background: $lightGray;
            }

            &:hover
            {
                background: $gray;
            }
        }
    }
}
