//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.calculationNavigationBar
{
    width:           100%;
    justify-content: flex-end;
    display:         flex;
    gap:             5px;
    align-items:     flex-end;

    @media (max-width: $screen-sm)
    {
        flex-wrap: wrap;
        gap:       unset;

        > button
        {
            flex:   1 1 120px;
            margin: 5px;
        }
    }
}
