//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/all';

.labelInfoText
{
    background-color: $darkerAbyss !important;
    padding:          20px !important;
    border-radius:    5px !important;
    box-shadow:       0 0 6px 0 $black50 !important;
    max-width:        260px !important;
    position:         absolute !important;
    pointer-events:   none !important;
    opacity:          1 !important;
    color:            $white !important;
    z-index:          $zIndexTooltip !important;
}

.labelInfoTextWrapper
{
    padding: 2px 2px 2px 7px;
    cursor:  pointer;
}