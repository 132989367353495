//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.guideScreen
{
    display:        flex;
    flex-direction: column;
    gap:            30px;

    .divider
    {
        display:               grid;
        grid-template-columns: 1fr 1fr;
        gap:                   100px;

        @media (max-width: $screen-sm)
        {
            grid-template-columns: 1fr;
        }

        ul
        {
            display:        flex;
            flex-direction: column;
            gap:            20px;
            width:          100%;

            li
            {
                display:        flex;
                gap:            20px;
                flex-direction: column;
                line-height:    1.5;

                a
                {
                    text-decoration: none;

                    &:hover
                    {
                        text-decoration: underline;
                    }
                }
            }
        }

        > div
        {
            display:        flex;
            flex-direction: column;
            gap:            20px;

            video
            {
                width: 100%;
            }
        }
    }
}
