//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.facilityList
{
    .headerRow
    {
        display:        grid;
        gap:            10px;
        font-weight:    $fontWeightBold;
        font-size:      14px;
        color:          $black;
        border-bottom:  1px solid $gray;
        padding-bottom: 10px;
    }

    .tableRowContainer
    {
        display: grid;
        gap:     10px;

        button
        {
            height: 100%;
            width:  100%;
            @include svgColor($mediumGray)
        }

        &:not(:last-of-type)
        {
            padding-bottom: 10px;
            border-bottom:  1px solid $gray;
        }

        &:not(:first-of-type)
        {
            margin-top: 10px;
        }
    }

    .emptyText
    {
        text-align: center;
        padding:    20px 0 20px 0;
    }
}
