//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.trafficLight
{
    display:         flex;
    align-items:     center;
    justify-content: center;
    height:          100%;
    width:           fit-content;
    position:        relative;

    .light
    {
        border-radius:   100%;
        margin:          0 5px;
        display:         flex;
        justify-content: center;
        align-items:     center;

        &.red
        {
            background-color: $faintingRed;
        }

        &.yellow
        {
            background-color: $yellow;
        }

        &.green
        {
            background-color: $lightGreen;
        }
    }

    .infoText
    {
        $width:         200px;

        background:     $darkerAbyss;
        text-align:     center;
        color:          $white;
        padding:        20px;
        border-radius:  5px;
        width:          $width;
        box-shadow:     0 0 6px 0 $black50;
        position:       absolute;
        bottom:         100%;
        left:           calc(50% - #{$width / 2});
        opacity:        0;
        pointer-events: none;
        transition:     opacity 0.2s ease-in-out;
    }

    &:hover
    {
        .infoText
        {
            opacity: 1;
        }
    }
}
