//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.confirmModal
{
    display: flex;
}

.modalContent
{
    display:         flex;
    flex-direction:  column;
    justify-content: space-between;

    &.fullHeight
    {
        height: 100%;
    }

    .modalFooter
    {
        display:         flex;
        flex-wrap:       wrap;
        gap:             20px;
        justify-content: space-between;
        margin-top:      20px;
    }
}
