//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

@mixin hidden()
{
    max-height: 0;
}

@mixin visible()
{
    max-height: 500px;
}

.mobileMenu
{
    position:  absolute;
    top:       80px;
    width:     100%;
    z-index:   $zIndexMobileMenu;

    @media (min-width: $screen-md)
    {
        display: none;
    }

    $iconSize: 25px;

    .mobileMenuHeader
    {
        padding-left: 20px;
        width:        50px;
        top:          0;
        position:     fixed;

        @include svgColor($white);

        button
        {
            height:        $headerHeight;
            margin-bottom: auto;

            svg
            {
                width: $iconSize;
            }
        }

    }

    .mobileMenuContent
    {
        @include hidden;

        overflow:         hidden;
        display:          flex;
        flex-direction:   column;
        justify-content:  space-between;
        position:         fixed;
        width:            100%;
        background-color: $darkAbyss;
        transition:       max-height 0.2s ease-in-out;

        a
        {
            text-decoration: none;

            button
            {
                width:  90%;
                margin: 0 auto 10px auto;
            }
        }

        .mobileMenuItem
        {
            opacity:    0;
            display:    flex;
            padding:    20px;
            gap:        20px;
            align-self: center;
            color:      $white;
            transition: background-color 0.2s ease-in-out,
                        color 0.2s ease-in-out,
                        padding-left 0.2s ease-in-out,
                        opacity 0.2s ease-in-out;

            @include svgFillStrokeTransition;

            svg
            {
                width: $iconSize;
            }

            span:first-child
            {
                width: fit-content;
            }

            span:last-child
            {
                display:         flex;
                justify-content: center;
                line-height:     1.5;
            }

            &:hover,
            &:global(.active)
            {
                cursor:       pointer;
                color:        $lightGreen;
                padding-left: 25px;

                svg
                {
                    @include svgColor($lightGreen);
                }
            }

            &:hover
            {
                background: $darkerAbyss;
            }
        }

    }

    &.open
    {
        .mobileMenuContent
        {
            @include visible;

            .mobileMenuItem
            {
                opacity: 1;
            }
        }
    }
}
