//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.packagesContainer
{
    display:         flex;
    justify-content: center;
    align-items:     center;
    padding:         20px;
    flex-wrap:       wrap;
    gap:             20px;
}

.subscriptionPackages
{
    display:        flex;
    flex-direction: column;
}

.infoText
{
    margin:     auto;
    color:      $darkGray;
    max-width:  800px;
    text-align: center;
}

.trialPhaseInformation
{
    margin:      auto;
    max-width:   800px;
    color:       $darkGray;
    text-align:  center;
    font-weight: $fontWeightBold;
}

.packageCard
{
    background: #ffffff;
    border:     $lightGray solid 2px;
    margin:     0 15px;
    padding:    20px;
    width:      300px;
    text-align: center;
    position:   relative;

    &.highlight
    {
        border: 2px solid $lightGreen;
    }
}

.packageTitle
{
    font-size:     1.5em;
    margin-bottom: 10px;
}

.packagePrice
{
    font-size:   2em;
    color:       $darkGreen;
    font-weight: bold;
    margin:      10px 0;
}

.packageTaxInformation
{
    font-size:      0.8em;
    color:          $darkGray;
    padding-bottom: 20px;
    border-bottom:  1px solid $lightGray;
}

.packageFeatures
{
    list-style: none;
    padding:    0;
    text-align: left;
}

.featureItem
{
    display: flex;
    margin:  10px 0;

    svg
    {
        margin-right: 8px;

        *
        {
            @include svgColor($lightGreen, true);
        }
    }
}

.buyButton
{
    background:    $darkGreen;
    color:         white;
    border:        none;
    padding:       10px 20px;
    border-radius: 4px;
    cursor:        pointer;
    font-size:     1em;

    &:hover
    {
        background: $lightGreen;
    }
}

.highlightLabel
{
    position:      absolute;
    top:           -16px;
    right:         -9px;
    background:    $lightGreen;
    color:         white;
    padding:       5px 10px;
    border-radius: 4px;
    font-size:     1.3em;
}
