//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.splitWidget
{
    width:          100%;
    height:         100%;
    border:         1px solid $lightGray;
    display:        flex;
    flex-direction: column;
    padding:        10px;

    .part
    {
        height:         100%;
        padding-bottom: 20px;

        &:first-child
        {
            border-bottom: 1px solid $lightGray;
            margin-bottom: 10px;
        }

        :first-child
        {
            text-transform: uppercase;
        }
    }

    .value
    {
        height:          100%;
        display:         flex;
        align-items:     center;
        justify-content: center;
        color:           $darkAbyss;
        font-size:       36px;
        font-weight:     $fontWeightBold;
    }
}
