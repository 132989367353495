//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.breadcrumbItem
{
    $height: 30px;

    display: flex;
    height:  $height;
    width:   100%;

    > span
    {
        @include svgColor($gray, true);

        width: fit-content;

        &:first-child
        {
            transform: translateX(.5px);
        }

        svg
        {
            height:      $height;
            width:       auto;
            margin-left: -1px;
        }
    }

    :global(.content)
    {
        background:     $gray;
        width:          100%;
        display:        flex;
        align-items:    center;
        padding:        0 10px;
        gap:            10px;
        font-size:      12px;
        text-transform: uppercase;
        font-weight:    $fontWeightSemiBold;

        @include svgColor($black);

        > span
        {
            width: fit-content;

            svg
            {
                $iconSize: 18px;

                width:     $iconSize;
                height:    $iconSize;
            }
        }
    }

    &:global(.active)
    {
        > span
        {
            @include svgColor($lightGreen, true);
        }

        :global(.content)
        {
            background: $lightGreen;
        }
    }
}
