//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.groupedTable
{
    width:      100%;
    overflow-y: hidden;
    height:     fit-content;

    @media (max-width: $screen-md)
    {
        > table
        {
            min-width: 1200px;
        }
    }

    .disabled
    {
        background-color: $grayChicago;
        cursor:           not-allowed;
    }
}
