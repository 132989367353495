//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.radioCheckBox
{
    padding: 12px 10px;

    .radioLabel
    {
        font-weight:   500;
        margin-bottom: 4px;
    }

    .radioOptions
    {
        display: flex;
    }

    .inputWrapper
    {
        position:     relative;
        user-select:  none;
        margin-top:   0;
        margin-right: 0.3125rem;
        margin-left:  0;
        min-width:    80px;

        &:first-child
        {
            padding-right: 60px;
        }

        .input
        {
            height: 20px;
            width:  20px;
        }
    }

    .label
    {
        display:       block;
        margin-bottom: .5rem;
    }

    .checkmark
    {
        position:         absolute;
        top:              0;
        left:             0;
        height:           20px;
        width:            20px;
        background-color: $lightGray;
        border-radius:    50%;
        text-indent:      26px;
        border:           1px solid $lightGray;
        cursor:           pointer;

        &::after
        {
            content:       "";
            position:      absolute;
            display:       none;
            top:           5px;
            left:          5px;
            width:         8px;
            height:        8px;
            border-radius: 50%;
            background:    white;
        }
    }

    input:checked ~ .checkmark
    {
        background-color: $lightGreen;
        border:           1px solid $darkGreen;

        &::after
        {
            display: block;
        }
    }
}
