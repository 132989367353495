//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

@mixin hidden()
{
    opacity:        0;
    pointer-events: none;
    transition:     opacity 0.1s ease-in-out;
}

@mixin visible()
{
    opacity:        1;
    pointer-events: all;
    transition:     opacity 0.1s ease-in-out 0.1s;
}

.sidebar
{
    // stylelint-disable
    $sidebarContentPaddingX: 16px;
    $sidebarContentPaddingY: 20px;
    // stylelint-enable

    position:                sticky;
    top:                     0;
    height:                  100vh;
    width:                   100%;
    max-width:               $sidebarWidth;
    background-color:        $darkAbyss;
    user-select:             none;
    transition:              max-width 0.1s ease-in-out;

    @media (max-width: $screen-md)
    {
        display: none;
    }

    $sidebarHeaderHeight:    130px;
    $iconSize:               25px;

    .sidebarHeader
    {
        $gap:            20px;

        display:         flex;
        justify-content: space-between;
        padding:         0 $sidebarContentPaddingX;
        align-items:     center;
        gap:             $gap;
        height:          $sidebarHeaderHeight;

        @include svgColor($white);

        img
        {
            max-width: calc(100% - $gap - $iconSize);
            width:     100%;

            @include visible;
        }

        button
        {
            height:        $headerHeight;
            margin-bottom: auto;

            svg
            {
                width: $iconSize;
            }
        }

    }

    .sidebarContent
    {
        overflow:        auto;
        height:          calc(100% - $sidebarHeaderHeight);
        display:         flex;
        flex-direction:  column;
        justify-content: space-between;

        @include visible;

        a
        {
            text-decoration: none;

            button
            {
                width:  90%;
                margin: 0 auto 10px auto;
            }
        }

        .sidebarItem
        {
            display:    flex;
            padding:    $sidebarContentPaddingY $sidebarContentPaddingX;
            gap:        20px;
            align-self: center;
            color:      $white;
            transition: background-color 0.2s ease-in-out,
                        color 0.2s ease-in-out,
                        padding-left 0.2s ease-in-out;

            @include svgFillStrokeTransition;

            svg
            {
                width: $iconSize;
                @include svgColor($white);
            }

            span:first-child
            {
                width: fit-content;
            }

            span:last-child
            {
                display:         flex;
                justify-content: center;
                line-height:     1.5;
            }

            &:hover,
            &:global(.active)
            {
                cursor:       pointer;
                color:        $lightGreen;
                padding-left: 25px;

                svg
                {
                    @include svgColor($lightGreen);
                }
            }

            &:hover
            {
                background: $darkerAbyss;
            }

            &.itemContracted
            {
                span:last-child
                {
                    display: none;
                }

                &:hover,
                &:global(.active)
                {
                    padding-left: $sidebarContentPaddingX;
                }
            }
        }
    }

    &.contracted
    {
        max-width:  60px;
        transition: max-width 0.1s ease-in-out 0.1s;

        .sidebarHeader
        {
            padding-left: 0;
            gap:          0;
            transition:   padding-left 0.1s ease-in-out 0.1s,
                          gap 0.1s ease-in-out 0.1s;

            button
            {
                margin-left: auto;
            }

            img
            {
                @include hidden;
            }
        }
    }
}
