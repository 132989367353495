//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.dashboardWidget
{
    width:          100%;
    height:         100%;
    border:         1px solid $lightGray;
    display:        flex;
    flex-direction: column;
    padding:        10px;

    h4
    {
        text-transform: uppercase;
        margin-bottom:  10px;
    }

    .content
    {
        height: 100%;
        width:  100%;
    }
}
