//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.dashboardScreen
{
    display:        flex;
    flex-direction: column;
    gap:            20px;

    .dashboardContainer
    {
        display:               grid;
        grid-template-columns: repeat(3, 1fr);
        gap:                   20px;

        > *
        {
            min-height: 300px;
        }
    }

    @media (max-width: $screen-tablet)
    {
        .dashboardContainer
        {
            grid-template-columns: repeat(2, 1fr);
            gap:                   15px;
        }
    }

    @media (max-width: $screen-phone)
    {
        .dashboardContainer
        {
            grid-template-columns: 1fr;
            gap:                   10px;

            > *
            {
                min-height: 240px;
            }
        }
    }
}