//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

/* stylelint-disable no-descending-specificity */

.button
{
    display:          flex;
    flex-flow:        row nowrap;
    align-items:      center;
    justify-content:  center;
    height:           fit-content;
    color:            $darkGray;
    border:           2px solid $lightGreen;
    padding:          6px 16px;
    border-radius:    6px;
    gap:              10px;
    min-width:        120px;
    background-color: $lightGreen;
    cursor:           pointer;
    transition:       color .2s linear,
                      border-color .2s linear,
                      background-color .2s linear,
                      transform .1s ease-in-out;

    svg
    {
        @include svgColor($darkGray);

        width:  18px;
        height: auto;
    }

    .iconContainer
    {
        display:     flex;
        align-items: center;
    }

    &.iconOnly
    {
        min-width: auto;

        .iconContainer
        {
            padding: 0 !important;
        }
    }

    &.icon
    {
        min-width:  auto;
        padding:    0;
        border:     none;
        background: transparent !important;

        &:hover
        {
            background: none;
        }
    }

    &.edgy
    {
        border-radius: 0;
        padding:       10px 20px;

        p
        {
            font-size: 14px;
        }
    }

    &[disabled]
    {
        cursor:           auto;
        color:            $white !important;
        border-color:     $doveGray !important;
        background-color: $doveGray !important;
        pointer-events:   none;

        &.outline,
        &.outlineIcon
        {
            color:            $doveGray !important;
            background-color: transparent !important;

            .iconContainer
            {
                svg
                {
                    @include svgColor($doveGray, true);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($white, true);
            }
        }
    }

    p
    {
        font-size:   12px;
        font-weight: $fontWeightSemiBold;
    }

    &:global(.white)
    {
        border-color:     $gray !important;
        background-color: $white !important;
    }

    &.pink
    {
        color:            $white !important;
        border-color:     $cyberPink !important;
        background-color: $cyberPink !important;
    }

    &.big
    {
        min-height: 50px;

        p
        {
            font-size: 18px;
        }
    }

    &.pressed
    {
        transform: scale(0.95) !important;
    }

    &:hover
    {
        transform: scale(1.05);
    }
}
