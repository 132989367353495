//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.loginWrapper
{
    display:         flex;
    width:           90%;
    max-width:       900px;
    height:          100vh;
    flex-direction:  column;
    margin:          0 auto;
    justify-content: center;
    gap:             10px;
    align-items:     center;

    h1
    {
        text-align: center;
    }

    img
    {
        width:         auto;
        height:        120px;
        margin-bottom: 20px;
    }

    > p
    {
        font-size: 24px;
    }

    .gridWrapper
    {
        display:               grid;
        grid-template-columns: 1fr 1fr;

        .signupFormWrapper
        {
            padding-top: 20px;
            text-align:  center;

            .signupForm
            {
                margin-top:     20px;
                gap:            20px;
                width:          100%;
                display:        flex;
                flex-direction: column;

                .agreementRow
                {
                    width:           100%;
                    display:         flex;
                    justify-content: center;
                    white-space:     break-spaces;

                    &.agreementRowError
                    {
                        color: $red;
                    }

                    > div > div > div > span > svg
                    {
                        @include svgColor($lightGreen, true);
                    }

                    .link
                    {
                        color:           $lightGreen;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @media (width <= 768px)
    {
        max-width: 600px;

        .gridWrapper
        {
            grid-template-columns: 1fr;
        }
    }
}
