//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/all';

.calculationCustomerScreen
{
    display:        flex;
    flex-direction: column;
    gap:            10px;

    .headerNavigationRow
    {
        display:         flex;
        align-items:     flex-end;
        justify-content: space-between;

        > *
        {
            width: 100%;
        }
    }

    .halfWidth
    {
        width:         calc(50% - 10px);
        margin-bottom: 20px;

        @media (max-width: $screen-sm)
        {
            width: 100%;
        }
    }

    .divider
    {
        display:               grid;
        grid-template-columns: 1fr 1fr;
        gap:                   20px;

        > div
        {
            display:        flex;
            flex-direction: column;
            gap:            10px;
        }

        @media (max-width: $screen-sm)
        {
            grid-template-columns: 1fr;
        }
    }
}
