//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.notificationHandler
{
    position: absolute;

    > div
    {
        z-index: $zIndexNotification !important;
    }
}

.notificationHandlerCenter
{
    // Disabled because of notification library classnames
    /* stylelint-disable */
    :global(.notification-container)
    {
        left:            0;
        right:           0;
        overflow:        hidden;
        margin:          0 auto;
        display:         flex;
        justify-content: center;
    }

    :global(.notification-enter)
    {
        transform: scale(0.5);
        opacity:   0;
    }

    :global(.notification-enter.notification-enter-active)
    {
        transform: scale(1);
        opacity:   1;
    }

    :global(.notification-exit)
    {
        transform: scale(1);
        opacity:   1;
    }

    :global(.notification-exit.notification-exit-active)
    {
        transform: scale(0.5);
        opacity:   0;
    }

    /* stylelint-enable */
}

.notificationHandlerLeft
{
    // Disabled because of notification library classnames
    /* stylelint-disable */
    :global(.notification-container)
    {
        left:  0;
        right: auto;
    }

    :global(.notification-enter)
    {
        transform: translate3d(-100%, 0, 0);
    }

    :global(.notification-enter.notification-enter-active)
    {
        transform: translate3d(0, 0, 0);
    }

    :global(.notification-exit)
    {
        transform: translate3d(0, 0, 0);
    }

    :global(.notification-exit.notification-exit-active)
    {
        transform: translate3d(-100%, 0, 0);
    }

    /* stylelint-enable */
}
