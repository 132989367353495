//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.alertBox
{
    padding:       12px 32px 12px 32px;
    border-radius: 4px;
    font-size:     18px;
    font-weight:   $fontWeightMedium;
    border-width:  1px;
    border-style:  solid;
    margin:        0 0 20px 0;
    position:      relative;

    &:last-child
    {
        margin-bottom: 0;
    }
}

.alertBoxCloseButtonWrapper
{
    position: absolute;
    top:      10px;
    right:    10px;
}

/* stylelint-disable no-descending-specificity */
// The rule is disabled because its not solvable no matter which order they are in.
.alertBoxSuccess
{
    border-color: $lightGreen;
    background:   $white;
    color:        $lightGreen;

    svg
    {
        @include svgColor($lightGreen);
    }

    .alertBoxIcon
    {
        background: $lightGreen;

        svg
        {
            @include svgColor($white);
        }
    }
}

.alertBoxError
{
    border-color: $faintingRed;
    background:   $white;
    color:        $faintingRed;

    svg
    {
        @include svgColor($faintingRed);
    }

    .alertBoxIcon
    {
        background: $faintingRed;

        svg
        {
            @include svgColor($white);

            margin: 1px 0 0 1px;
        }
    }
}

/* stylelint-enable no-descending-specificity */
