//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.colorPicker
{
    position: relative;

    p
    {
        font-weight: $fontWeightMedium;
        font-size:   14px;
    }

    .colorPreview
    {
        width:         170px;
        height:        30px;
        border-radius: 6px;
        border:        $lightGray solid 2px;
    }

    .pickerWrapper
    {
        position:       absolute;
        top:            54px;
        z-index:        10;
        overflow:       hidden;
        opacity:        0;
        pointer-events: none;
        transition:     opacity 0.2s ease-in-out;

        /* stylelint-disable selector-class-pattern */
        :global(.sketch-picker)
        {
            background: $darkAbyss !important;

            label
            {
                color: $white !important;
            }
        }

        /* stylelint-enable selector-class-pattern */

        &.show
        {
            opacity:        1;
            pointer-events: all;
        }

        .popover
        {
            position: fixed;
            inset:    0 0 0 0;
        }
    }
}
