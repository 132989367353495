//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.expandable
{
    transition: border 0.2s ease-in-out;
    border:     1px solid $transparent;

    &:hover
    {
        border-color: $gray;
    }

    .expandableHeader
    {
        display:    flex;
        background: $lightGray;
        cursor:     pointer;

        .iconContainer
        {
            display:         flex;
            align-items:     center;
            justify-content: center;
            width:           30px;

            @include svgColor($black);

            span
            {
                width:  fit-content;
                height: fit-content;

                svg
                {
                    $iconSize:  13px;

                    width:      $iconSize;
                    height:     $iconSize;
                    transition: transform 0.2s ease-in-out;
                }
            }
        }

        .headerRow
        {
            width:   100%;
            display: grid;
            height:  100%;
            padding: 10px 10px;

            .rowColumnWrapper
            {
                width:           100%;
                display:         flex;
                justify-content: center;
                height:          100%;

                .rowColumn
                {
                    width:   100%;
                    display: flex;
                    gap:     5px;

                    .prefixLine
                    {
                        height:     100%;
                        width:      4px;
                        background: $white;
                    }

                    div:last-child
                    {
                        display:         flex;
                        flex-direction:  column;
                        justify-content: center;
                        gap:             5px;

                        div:first-child
                        {
                            display:     flex;
                            font-weight: $fontWeightMedium;
                            font-size:   13px;
                        }

                        div:last-child
                        {
                            font-size: 12px;
                        }
                    }
                }
            }

            .expandButtonWrapper
            {
                display:         flex;
                justify-content: flex-end;
                align-items:     center;

                svg
                {
                    $iconSize:  12px;

                    width:      $iconSize;
                    height:     $iconSize;
                    transition: transform 0.2s ease-in-out;
                }
            }
        }

        &.expanded
        {
            .iconContainer
            {
                svg
                {
                    transform: rotate(90deg);
                }
            }

            .headerRow
            {
                .expandButtonWrapper
                {
                    svg
                    {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

    .expandableWrapper
    {
        background: $lightGray;
        transition: max-height 0.2s ease-in-out,
                    margin-top 0.2s ease-in-out;
        height:     fit-content;
        max-height: 0;
        overflow:   hidden;
        position:   relative;

        .expandableContent
        {
            margin-bottom: 20px;
            padding:       0 10px;
            transition:    opacity 0.1s ease-in-out;
            opacity:       0;

            .separator
            {
                margin:     10px 0;
                width:      100%;
                height:     4px;
                background: $white;
            }
        }

        &.expanded
        {
            max-height: 1000px;
            transition: max-height 0.2s ease-in-out 0.1s,
                        margin-top 0.2s ease-in-out 0.1s;

            .expandableContent
            {
                transition: opacity 0.1s ease-in-out 0.2s;
                opacity:    1;
            }
        }
    }
}

@media (max-width: $screen-md)
{
    .expandableHeader
    {
        .headerRow
        {
            grid-template-columns: 1fr !important;
            flex-direction:        row !important;

            .rowColumnWrapper
            {
                &:not(:first-child)
                {
                    display: none;
                }
            }
        }
    }
}
