//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/all';

.calculationFinanceScreen
{
    display:        flex;
    flex-direction: column;
    gap:            10px;

    .headerNavigationRow
    {
        display: flex;

        > *
        {
            width: 100%;
        }
    }

    .divider
    {
        display:               grid;
        grid-template-columns: 3fr 1fr;
        gap:                   20px;

        @media (max-width: $screen-sm)
        {
            grid-template-columns: 1fr;
        }

        > div
        {
            display:        flex;
            flex-direction: column;
            gap:            40px;

            .gridRow
            {
                display:               grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap:                   20px;
            }

            &:nth-child(2)
            {
                > *
                {
                    height: fit-content;
                }
            }
        }
    }
}
