//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.settingsScreen
{
    display:        flex;
    flex-direction: column;
    gap:            20px;

    .settingsInputWrapper
    {
        max-width: $singleColumnFormWidth;

        button
        {
            width:      fit-content;
            min-width:  200px;
            margin-top: 20px;
        }
    }
}
