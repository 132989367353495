//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.labeledRangeTextInput
{
    display:        flex;
    flex-direction: column;
    gap:            4px;
    margin-bottom:  10px;

    .sliderWrapper
    {
        input
        {
            width:         100%;
            border-radius: 0;
            background:    $lightGreen;
            appearance:    none;
            height:        2px;

            &::-webkit-slider-thumb
            {
                appearance:    none;
                width:         20px;
                height:        20px;
                border-radius: 50%;
                background:    $mediumGray;
                cursor:        pointer;
                box-shadow:    0 0 2px 0 rgb(0 0 0 / 20%);
            }
        }
    }
}
