//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.newsWidget
{
    position:       relative;
    display:        flex;
    background:     $darkAbyss;
    border:         $darkerAbyss 1px solid;
    padding:        20px;
    container-type: inline-size;
    container-name: faqwidget;

    .contentWrapper
    {
        display:        flex;
        flex-direction: column;

        > *
        {
            margin-bottom: 30px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }
    }
}
