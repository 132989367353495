//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// stylelint-disable primer/no-unused-vars
$fontWeightExtraLight: 200;
$fontWeightLight:      300;
$fontWeightRegular:    400;
$fontWeightMedium:     500;
$fontWeightSemiBold:   600;
$fontWeightBold:       700;
$fontWeightExtraBold:  800;
$fontWeightBlack:      900;
