//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.breadCrumbBar
{
    display:       flex;
    width:         100%;
    margin-bottom: 20px;

    @media (max-width: $screen-sm)
    {
        flex-wrap: wrap;
        gap:       7px;
    }
}
