//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableHeaderCell
{
    font-size:     14px;
    font-weight:   bold;
    display:       flex;
    padding:       20px 6px;
    border-bottom: 1px solid $gray;
    text-align:    left;
    white-space:   nowrap;
}
