//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.customerOnlineCalculatorScreen
{
    display:        flex;
    flex-direction: column;
    gap:            30px;

    h3
    {
        text-transform: uppercase;
    }

    .grid
    {
        display:               grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:              20px;

        @media (max-width: $screen-sm)
        {
            grid-template-columns: 1fr;
        }

        > div
        {
            display:        flex;
            flex-direction: column;
            gap:            10px;
            padding:        20px;
            border:         $lightGray solid 2px;

            h3
            {
                text-transform: uppercase;
            }

            p
            {
                line-height: 1.5;
            }
        }
    }
}
