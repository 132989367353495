//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$darkGreen:   #00837b;
$lightGreen:  #0ff4a7;
$lightGray:   #f2f2f2;
$darkGray:    #282828;
$gray:        #e3e3e3;
$darkAbyss:   #313c54;
$darkerAbyss: #21293a;
$black:       #000000;
$black10:     rgba($black, 0.1);
$black30:     rgba($black, 0.3);
$black50:     rgba($black, 0.5);
$black80:     rgba($black, 0.8);
$grayTuatara: #3c3c3b;
$dustGray:    #999999;
$mediumGray:  #a6a6a6;
$gravelGray:  #807f7f;
$grayChicago: #565655;
$white:       #ffffff;
$mandyRed:    #ea5172;
$faintingRed: rgb(229 94 89);
$yellow:      rgb(250 227 76);
$white50:     rgba($white, 0.5);
$white80:     rgba($white, 0.8);
$cyberPink:   #f523d5;
$transparent: transparent;
$doveGray:    #646363;
$red:         #ff0000;

/* stylelint-disable property-case, property-no-unknown */
:export
{
    lightGreen:  $lightGreen;
    darkAbyss:   $darkAbyss;
    darkerAbyss: $darkerAbyss;
    white:       $white;
    black:       $black;
    black10:     $black10;
}

/* stylelint-enable property-case, property-no-unknown */

