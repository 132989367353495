//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.loginWrapper
{
    display:         flex;
    width:           90%;
    max-width:       600px;
    height:          100vh;
    flex-direction:  column;
    margin:          0 auto;
    justify-content: center;
    gap:             10px;
    align-items:     center;

    img
    {
        width:         auto;
        height:        120px;
        margin-bottom: 20px;
    }

    > p
    {
        font-size: 24px;
    }

    form
    {
        margin-top:     20px;
        gap:            20px;
        width:          100%;
        display:        flex;
        flex-direction: column;
    }
}