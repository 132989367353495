//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.labeledInputWrapper
{
    width:          100%;
    display:        flex;
    flex-direction: column;

    :global(.preIconContainer)
    {
        background:  $mediumGray;
        display:     flex;
        align-items: center;
        padding:     0 5px;
        height:      18px;

        svg
        {
            width: 14px;
            @include svgColor($white);
        }
    }

    .inputWrapper
    {
        align-items: center;
        display:     flex;

        &.multiline
        {
            textarea
            {
                height: 200px;
                width:  100%;
                resize: vertical;
            }
        }
    }

    .labelWrapper
    {
        position: relative;
        display:  flex;
        gap:      5px;

        label
        {
            display: flex;
        }
    }

    .labeledInput
    {
        background: $lightGray;
        width:      100%;
        padding:    3px 10px;
        height:     fit-content;
        border:     2px solid $lightGray;

        label
        {
            color:     $gravelGray;
            font-size: 12px;
        }

        .postIconContainer
        {
            svg
            {
                width: 14px;
                @include svgColor($mediumGray);
            }
        }

        &.light
        {
            background:    $transparent;
            border-radius: 6px;
            margin-top:    10px;

            label
            {
                transform:   translateY(-12px);
                background:  $white;
                position:    absolute;
                padding:     0 8px;
                font-weight: $fontWeightBold;
                color:       $darkGray;
                font-size:   12px;
            }

            input
            {
                margin-top: 6px;
            }
        }

        &.error
        {
            border: 2px solid $red;
        }

        &.lightWithoutLabel
        {
            background:    $transparent;
            border-radius: 6px;

            label
            {
                display: none;
            }
        }
    }

    &:global(.highlight)
    {
        border:      2px solid $mediumGray;
        border-left: none;
    }

    &.abyss
    {
        .preIconContainer
        {
            background: $darkAbyss;
        }

        &.highlight
        {
            border-color: $darkAbyss;
        }
    }
}

.bottomInfoText
{
    color:      $grayChicago;
    font-size:  10px;
    margin-top: 5px;
}
