//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.screen
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: flex-start;

    .contentWrapper
    {
        width: 100%;

        .contentContainer
        {
            width:           100%;
            display:         flex;
            flex-flow:       column nowrap;
            justify-content: space-between;
            min-height:      calc(100vh - $headerHeight);
            padding:         20px;
            gap:             40px;
        }
    }
}
