//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableBodyCell
{
    font-size:     14px;
    font-weight:   normal;
    padding:       10px 5px;
    border-bottom: 1px solid $gray;
    text-align:    left;
    transition:    background-color 0.1s linear;
    display:       flex;
    align-items:   center;

    &.tableBodyCellAlignmentLeft
    {
        text-align: left;
    }

    &.tableBodyCellAlignmentRight
    {
        text-align: right;

        > *
        {
            float: right;
        }
    }

    > strong
    {
        font-weight:     normal;
        text-decoration: underline;
        display:         block;
        margin:          0 0 5px 0;
        padding:         0;
    }

    &:last-child
    {
        justify-content: flex-end;
    }
}
