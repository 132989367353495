//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.labeledWrapper
{
    display:   flex;
    flex-flow: column;
    position:  relative;

    > label
    {
        position:    absolute;
        color:       $black;
        font-weight: $fontWeightMedium;
        font-size:   14px;
        background:  $white;
        padding:     0 5px;
        transform:   translateY(-6px);
    }

    .wrapperGrid
    {
        display:         grid;
        width:           100%;
        gap:             10px 25px;
        padding:         30px 20px 20px 20px;
        border-radius:   5px;
        border:          2px solid $lightGray;
        justify-content: space-between;

        @media (max-width: $screen-sm)
        {
            grid-template-columns: 1fr !important;
            gap:                   5px 5px;
            padding:               20px 5px;
            justify-content:       center;
        }

        &.wrapperGridFullHeight
        {
            height: 100%;
        }

        &.alignVerticalCenter
        {
            align-items: center;
        }
    }

    &.small
    {
        > label
        {
            font-weight: $fontWeightBold;
            font-size:   12px;
            margin-left: 12px;
            padding:     0 8px;
            transform:   translateY(-6px);
        }
    }
}
