//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.rangeSliderWrapper
{
    display:        flex;
    flex-direction: column;
    padding:        12px 10px;
    font-weight:    500;
    position:       relative;

    .number
    {
        margin-top: 5px;
    }

    .rangeSliderTest
    {
        appearance:    none;
        width:         100%;
        height:        30px;
        border-radius: 0;
        background:    transparent;
        border-bottom: 1px solid #ffffff;
        outline:       none;
        opacity:       0.7;
        transition:    opacity .2s;

        &:hover
        {
            opacity: 1;
        }
    }

    .rangeSlider
    {
        width: 100%;
    }

    .rangeSliderTest::-webkit-slider-thumb
    {
        appearance:       none;
        width:            20px;
        height:           20px;
        border-radius:    50% 50% 0;
        transform:        rotate(45deg);
        border:           1px solid #3c3c3b;
        background-color: #ffffff;
        cursor:           pointer
    }

    .rangeSliderTest.upside::-webkit-slider-thumb
    {
        transform: rotate(225deg)
    }

    .rangeSliderTest::-moz-range-thumb
    {
        width:            20px;
        height:           20px;
        border-radius:    50% 50% 0;
        transform:        rotate(45deg);
        border:           1px solid #3c3c3b;
        background-color: #ffffff;
        cursor:           pointer
    }

    .rangeSliderTest.upside::-moz-range-thumb
    {
        transform: rotate(225deg)
    }
}
