//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// stylelint-disable selector-class-pattern
@import '../../../../styles/all';

.dropdownContainer
{
    .labelContainer
    {
        position:    absolute;
        z-index:     10;
        transform:   translateY(2px) translateX(10px);
        background:  $white;
        padding:     0 8px;
        color:       $black;
        font-size:   14px;
        font-weight: $fontWeightMedium;
    }

    :global(.wrapper)
    {
        :global(.react-select__control)
        {
            color:      $black;
            border:     none;
            box-shadow: none;
            outline:    none;

            &:global(.react-select__control--menu-is-open)
            {
                border-radius: 4px 4px 0 0;
            }
        }

        :global(.react-select__placeholder)
        {
            color: $black;
        }

        :global(.react-select__indicator-separator)
        {
            display: none;
        }

        :global(.react-select__dropdown-indicator)
        {
            color: $black;
        }

        :global(.react-select__menu)
        {
            margin:        0;
            border-radius: 0 0 4px 4px;
            background:    $white;
            border:        solid 2px;
            box-shadow:    none;
        }

        :global(.react-select__menu-list)
        {
            padding: 0;
        }

        :global(.react-select__option)
        {
            padding-left: 8px;
            transition:   background-color 0.2s ease-in-out;

            &:global(.react-select__option--is-selected)
            {
                background:  $white;
                color:       $black;
                font-weight: $fontWeightMedium;
            }
        }
    }

    &:global(.whiteWithLabel),
    &:global(.white)
    {
        :global(.wrapper)
        {
            border:        $lightGray 2px solid !important;
            border-radius: 5px;

            :global(.react-select__control)
            {
                background: $white !important;

                *
                {
                    color: $black !important;
                }
            }

            :global(.react-select__menu)
            {
                border: solid 1px $gray !important;
            }
        }
    }

    &:global(.whiteWithLabel)
    {
        :global(.wrapper)
        {
            margin-top: 10px;
            padding:    6px 0 0 0;
        }
    }
}

// stylelint-enable selector-class-pattern
