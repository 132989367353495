//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.checkBoxWrapper
{
    display:        flex;
    flex-direction: column;
    margin:         0 0 20px 0;
}

.checkBoxWrapperNoMarginBottom
{
    margin-bottom: 0;
}

.checkBoxWrapperClickArea
{
    cursor:         pointer;
    display:        flex;
    flex-direction: row;
    align-items:    center;
}

.checkBoxWrapperLabel
{
    font-size:   15px;
    font-weight: $fontWeightRegular;
    margin:      0 0 0 14px;
    user-select: none;
}

.checkBox
{
    border:        1px solid $gray;
    border-radius: 4px;
    position:      relative;
}

.checkBoxIconPlaceholder
{
    display: block;
}

.checkBoxChecked
{
    span
    {
        height: 100%;
        top:    0;
        left:   0;
    }

    svg
    {
        @include svgColor($black);

        height: 100%;
        margin: 0 0 0 0;
    }
}
