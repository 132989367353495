//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.footer
{
    display:         flex;
    flex-direction:  row;
    justify-content: space-between;
    padding:         10px 60px;
    color:           $black;

    .linkWrapper
    {
        display: flex;
        gap:     20px;

        a
        {
            text-decoration: none;
            color:           $black;
            transition:      color 0.2s ease-in-out;

            &::after
            {
                content:          '';
                display:          block;
                width:            100%;
                height:           2px;
                margin:           0 auto;
                background-color: $lightGreen;
                opacity:          0;
                transition:       opacity 0.2s ease-in-out;
            }

            &:hover
            {
                color: $lightGreen;

                &::after
                {
                    opacity: 1;
                }
            }
        }
    }

    .copyright
    {
        text-align: center;
    }

    @media (max-width: $screen-sm)
    {
        flex-direction: column;
        align-items:    center;
        padding:        0;

        .linkWrapper
        {
            flex-direction: column;
            gap:            2px;
            align-items:    center;
            margin-bottom:  5px;

            a
            {
                font-size: 14px;
            }
        }

        .copyright
        {
            font-size: 10px;
        }
    }
}
