//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.frameChart
{
    border: 0;
}

.frameChartWrapper
{
    position: unset;
    display:  grid;
    height:   100%;
}

.wrapper
{
    position: relative;
    height:   100%;
}

.zoom
{
    position: absolute;
    bottom:   5px;
    right:    5px;
    cursor:   pointer;
}
