//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.textInputWrapper
{
    $inputHeight: 40px;

    width:        100%;
    position:     relative;

    .postIconWrapper
    {
        pointer-events:  none;
        position:        absolute;
        top:             0;
        right:           0;
        height:          $inputHeight;
        width:           $inputHeight;
        display:         flex;
        justify-content: center;
        align-items:     center;
        box-sizing:      border-box;
    }

    &.textInputWrapperSmall
    {
        $inputHeightSmall: 21px;

        .textInput
        {
            padding:    0 4px;
            height:     $inputHeightSmall;
            min-height: $inputHeightSmall;
        }
    }

    &.textInputWrapperTextAlignmentLeft
    {
        .textInput
        {
            text-align: left;
        }
    }

    &.textInputWrapperTextAlignmentRight
    {
        .textInput
        {
            text-align: right;
        }
    }
}
