//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.topBar
{
    display:    flex;
    width:      100%;
    background: $darkerAbyss;
    height:     $headerHeight;
    position:   sticky;
    z-index:    $zIndexTopBar;
    top:        0;

    .topBarContent
    {
        width:           100%;
        display:         flex;
        justify-content: flex-end;
        align-self:      center;
        padding:         0 20px;
    }
}
