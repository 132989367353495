//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.profileMenu
{
    $profileMenuHeight: 70px;

    display:            flex;
    align-items:        center;
    gap:                20px;
    position:           relative;
    height:             $headerHeight;

    .avatarWrapper
    {
        $avatarSize:     56px;

        width:           $avatarSize;
        height:          $avatarSize;
        border-radius:   100%;
        background:      $lightGreen;
        display:         flex;
        justify-content: center;
        align-self:      center;
        padding:         2px;

        img
        {
            background:    white;
            width:         100%;
            height:        100%;
            border-radius: 100%;
            object-fit:    cover;
        }
    }

    .userWrapper
    {
        color:       $white;
        display:     flex;
        width:       fit-content;
        align-items: center;
        gap:         10px;
        font-weight: $fontWeightMedium;

        > span
        {
            @include svgColor($white);

            width: fit-content;
        }
    }

    .menuWrapper
    {
        position:       absolute;
        width:          300px;
        background:     $darkAbyss;
        border:         1px $darkerAbyss solid;
        right:          0;
        top:            $headerHeight;
        opacity:        0;
        pointer-events: none;
        transition:     opacity 0.1s ease-in-out;

        .menuItem
        {
            width:       100%;
            height:      50px;
            display:     flex;
            cursor:      pointer;
            align-items: center;
            padding:     0 20px;
            font-size:   15px;
            color:       $white;
            transition:  color 0.2s ease-in-out,
                         background 0.2s ease-in-out;

            span
            {
                width: 50px;
                @include svgColor($white);

                svg
                {
                    $size:  24px;

                    width:  $size;
                    height: $size;
                }
            }

            &:hover
            {
                background: $darkerAbyss;
                color:      $lightGreen;
                @include svgColor($lightGreen);

            }
        }
    }

    &:hover
    {
        .menuWrapper
        {
            opacity:        1;
            pointer-events: all;
        }
    }
}
