//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.notFoundScreen
{
    $parentContainerPadding: 10px;

    display:                 flex;
    flex-direction:          column;
    justify-content:         center;
    align-items:             center;
    gap:                     10px;
    padding:                 10px;
    height:                  calc(100vh - $headerHeight - $parentContainerPadding);

    img
    {
        width:         100%;
        max-width:     200px;
        margin-bottom: 20px;
    }

    .notFoundText
    {
        font-size: 24px;
    }
}