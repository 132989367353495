//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.customersScreen
{
    display:        flex;
    flex-direction: column;
    gap:            20px;

    h2
    {
        margin-bottom: 20px;
    }

    button
    {
        max-width: fit-content;
    }

    .customers
    {
        display:        flex;
        flex-direction: column;
        gap:            10px;
    }
}
