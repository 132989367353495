//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/all';

.calculationResultScreen
{
    display:        flex;
    flex-direction: column;
    gap:            10px;

    .headerNavigationRow
    {
        display: flex;

        *:first-child
        {
            text-wrap: nowrap;
        }
    }

    .grid
    {
        gap:                   20px;
        display:               grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media (max-width: $screen-sm)
        {
            grid-template-columns: 1fr;
        }

        .labelWrapper
        {
            width:          100%;
            display:        flex;
            flex-direction: column;
            gap:            5px;

            > *
            {
                height: fit-content;
            }

            .buttonGroup
            {
                width:   100%;
                display: flex;
                gap:     5px;

                > button
                {
                    width: 100%;
                }
            }
        }

        .chartHeight
        {
            min-height: 300px;
            @media (min-width: $screen-md)
            {
                min-height: 400px;
            }
        }

        .sliderWrapper
        {
            display:        flex;
            flex-direction: column;
            gap:            20px;
        }
    }
}
