//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.widgetUnit
{
    font-size:        0.9rem;
    display:          flex;
    flex-direction:   row;
    justify-content:  space-between;
    background-color: $grayChicago;
    padding:          10px;
    cursor:           pointer;

    .unitInformation
    {
        flex-wrap: wrap;

        .unitInformationDetails
        {
            display:   flex;
            flex-wrap: wrap;

            > span
            {
                white-space: break-spaces;
            }
        }
    }
}
