//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.editCustomerScreen
{
    display:        flex;
    flex-direction: column;
    gap:            30px;

    .divider
    {
        display:               grid;
        grid-template-columns: 1fr 1fr;
        gap:                   20px;

        @media (max-width: $screen-sm)
        {
            grid-template-columns: 1fr;
        }

        > *
        {
            display:        flex;
            flex-direction: column;
            gap:            20px;
        }
    }

    button
    {
        width: fit-content;
    }
}
