//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.widgetContactForm
{
    display:   flex;
    flex-wrap: wrap;
    padding:   30px 0;

    p
    {
        margin-bottom: 1rem;
    }

    .contactForm
    {
        text-align: center !important;
    }

    .contactFormInput
    {
        label
        {
            display:       inline-block;
            margin-bottom: 0.5rem;
        }

        margin-bottom: 1rem;
        padding-right: 15px;
        padding-left:  15px;

        input
        {
            border-radius:    0.25rem;
            background-color: $white;
            height:           calc(1.5em + .75rem + 2px);
            padding:          .375rem .75rem;
        }
    }

    .emailRow
    {
        width: 100%;

        @media (width >= 768px)
        {
            flex:         0 0 50%;
            max-width:    50%;
            margin-left:  auto;
            margin-right: auto;
        }
    }

    .contactFormInputs
    {
        display:      flex;
        flex-wrap:    wrap;
        margin-left:  auto;
        margin-right: auto;

        > div
        {
            width: 100%;

            @media (width >= 992px)
            {
                flex:      0 0 33.33%;
                max-width: 33.33%;
            }

            @media (width >= 768px)
            {
                flex:      0 0 50%;
                max-width: 50%;
            }
        }
    }

    .agreementRow
    {
        width:           100%;
        display:         flex;
        justify-content: center;
        white-space:     break-spaces;
        padding-left:    1.25rem;
    }

    .buttonRow
    {
        width:           100%;
        display:         flex;
        justify-content: center;
        margin-top:      1rem;
    }
}
