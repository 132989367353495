//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$inputHeight: 30px;

.textInput
{
    width:       100%;
    background:  $transparent;
    border:      none;
    height:      $inputHeight;
    min-height:  $inputHeight;
    outline:     none;
    font-family: $fontSourceGeneralBody;
    font-size:   16px;
    color:       $darkGray;
    box-sizing:  border-box;
    resize:      none;

    &.textInputResizable
    {
        resize: both;
    }

    &.textInputDisabled
    {
        cursor: not-allowed;
    }

    &.textInputMultiLine
    {
        padding-top: 9px;
        min-width:   150px;
    }

    &.textInputSingleLine
    {

    }

    &.textInputWithPostIcon
    {
        padding-right: $inputHeight;
    }

    &::placeholder
    {
        color:      $gray;
        font-style: italic;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
    {
        appearance: none;
        margin:     0;
    }

    &[type="number"]
    {
        appearance: textfield;
    }

    &:focus
    {
        border-color: $gray;
    }
}
