//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.widgetScreen
{
    width:            100%;
    background-color: $grayTuatara;

    .widgetScreenContainer
    {
        @media (width >= 1200px)
        {
            max-width: 1140px;
        }

        @media (width >= 992px) and (width <= 1199px)
        {
            max-width: 960px;
        }

        @media (width >= 768px) and (width <= 991px)
        {
            max-width: 720px;
        }

        @media (width >= 576px) and (width <= 767px)
        {
            max-width: 540px;
        }

        padding-right: 15px;
        padding-left:  15px;
        margin-right:  auto;
        margin-left:   auto;
        display:       flex;

        .flexRow
        {
            display:      flex;
            flex-wrap:    wrap;
            margin-right: -15px;
            margin-left:  -15px;
        }

        .widgetUnitAndSliderWrapper
        {
            display:   flex;
            flex-wrap: wrap;

            @media (width >= 992px)
            {
                flex:      0 0 75%;
                max-width: 75%;
            }
        }

        .widgetResultWrapper
        {
            width: 100%;

            @media (width >= 992px)
            {
                flex:      0 0 25%;
                max-width: 25%;
            }
        }

        .widgetScreenUnits
        {
            width: 100%;

            @media (width >= 992px)
            {
                flex:      0 0 33.33%;
                max-width: 33.33%;
            }

            & > div:not(:last-child)
            {
                margin-bottom: 2px
            }
        }

        .widgetSlidersWrapper
        {
            width: 100%;

            @media (width >= 992px)
            {
                flex:      0 0 66.6667%;
                max-width: 66.6667%;
            }

            .widgetSliders
            {
                display:   flex;
                flex-wrap: wrap;

                > div
                {
                    width: 100%;
                    @media (width >= 992px)
                    {
                        flex:      0 0 50%;
                        max-width: 50%;
                    }
                }
            }
        }

    }
}
