//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.modalContent
{
    height:         100%;
    display:        flex;
    flex-direction: column;

    .modalFooter
    {
        display:         flex;
        justify-content: flex-end;
        margin-top:      20px;
    }
}
